import CallIcon from '@mui/icons-material/AddIcCallRounded';
import CloseIcon from '@mui/icons-material/Close';
import TouchIcon from '@mui/icons-material/TouchAppRounded';
import { AppBar, Box, ButtonBase, Dialog, Fade, Grid, IconButton, Slide, styled, Toolbar, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useInView } from "react-intersection-observer";

import { TransitionProps } from "@mui/material/transitions";
import { contactFormText } from "../../texts/contactFormText";
import { Work, workGalleryText } from "../../texts/workGalleryText";
import theme from "../../theme";

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    height: 'calc(25vh + 18vw)',
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    //backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
}));

interface GalleryTileProps {
    image: Work;
}

export const GalleryTile: React.FC<GalleryTileProps> = ({ image }) => {
    const [galleryImg, setGalleryImg] = React.useState<Work>(workGalleryText.works[0]);
    const [open, setOpen] = React.useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { ref, inView } = useInView({
        triggerOnce: true,
    });


    const handleClickOpen = (image: Work) => {
        setOpen(true);
        setGalleryImg(image);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div ref={ref}>
            <Fade in={inView} timeout={1000}>
                <ImageButton
                    onClick={() => handleClickOpen(image)}
                    focusRipple
                    key={image.title}
                    style={{
                        width: '100%',
                    }}
                >
                    <ImageSrc style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL + image.url
                            })`
                    }} />
                    <ImageBackdrop className="MuiImageBackdrop-root" />
                    <Image>
                        <Typography
                            component="span"
                            variant="subtitle1"
                            color="inherit"
                            sx={{
                                position: 'relative',
                                p: 4,
                                pt: 2,
                                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                            }}
                        >
                            {image.title}
                        </Typography>
                        <TouchIcon sx={{ position: 'absolute', mt: 15 }} />
                    </Image>
                </ImageButton>
            </Fade>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={true}
                maxWidth='xl'
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'sticky' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
                            {image.title}
                        </Typography>
                        
                            <IconButton size='small'
                                href="tel:+421911671234"
                                sx={{
                                    ml: 0,
                                    pt: 1,
                                    color: 'white',
                                    fontSize: 28
                                }}>
                                <CallIcon fontSize='inherit' />
                            </IconButton>
                            <Typography variant="h6" sx={{ display: { xs: 'none', sm: 'flex' }, ml: 1 }}>{contactFormText.phone}</Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Grid container
                    sx={{
                        mt: { xs: 5, sm: 0 }
                    }}
                    rowSpacing={1}
                    rowGap={{ xs: 0.5, sm: 1, md: 2 }}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    columns={{ xs: 9, lg: 18 }}>
                    <Grid item xs={9}>
                        <Box
                            sx={{
                                boxShadow: 10,
                                margin: { sm: 5, xs: 1, md: 10, lg: 5 },
                                width: 'auto',
                                height: 'auto',
                            }}
                        >
                            <img src={process.env.PUBLIC_URL + galleryImg.afterurl} style={{ width: '100%', height: '100%', display: 'block' }} />
                        </Box>


                    </Grid>
                    <Grid item xs={9}>
                        <StyledBox sx={{
                            boxShadow: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            p: { xs: 3, sm: 5 },
                            mt: { xs: 0, lg: 5 },
                            ml: { xs: 1, sm: 5, md: 8, lg: 0 },
                            mr: { xs: 1, sm: 5, md: 8 },
                            mb: { xs: 1, sm: 5, md: 8 },
                        }}>
                            <Typography
                                sx={{
                                    fontSize: {
                                        xs: 'h6.fontSize',
                                        sm: 'h5.fontSize',
                                        md: 'h5.fontSize',
                                        lg: 'h4.fontSize',
                                    },
                                    color: 'white',
                                }}
                            >{galleryImg.description}</Typography>
                        </StyledBox>
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );
}