import CallIcon from '@mui/icons-material/AddIcCallRounded';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import { baseurl } from '../../router';
import { contactFormText } from '../../texts/contactFormText';







function NavBar() {

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const navigate = useNavigate();


  const scrollToComponent = (id: string) => () => {
    setOpen(false);
    navigate(baseurl + "/", { state: { targetId: id } });
  };


  function DrawerItems() {
    return (
      <List>
        <ListItem
          disablePadding>
          <ListItemButton
            onClick={() => { navigate(baseurl + "/"); }}
            sx={{ pr: 10, pl: 3 }}>
            <ListItemText>
              <Typography variant="h5" >Servis okien</Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding>
          <ListItemButton
             onClick={() => { navigate(baseurl + "/tieniaca-technika"); }}
            sx={{ pr: 10, pl: 3 }}>
            <ListItemText>
              <Typography variant="h5" >Tieniaca technika</Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding>
          <ListItemButton
             onClick={() => { navigate(baseurl + "/siete-proti-hmyzu"); }}
            sx={{ pr: 10, pl: 3 }}>
            <ListItemText>
              <Typography variant="h5" >Siete proti hmyzu</Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding>
          <ListItemButton
             onClick={() => { navigate(baseurl + "/plastove-okna"); }}
            sx={{ pr: 10, pl: 3 }}>
            <ListItemText>
              <Typography variant="h5" >Plastové okná</Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding>
            <Link to="qna" smooth={true} duration={750} onClick={toggleDrawer(false)}>
          <ListItemButton
            sx={{ pr: 10, pl: 3 }}>
            <ListItemText>
              <Typography variant="h5" >Otázky a odpovede</Typography>
            </ListItemText>
          </ListItemButton>
          </Link>
        </ListItem>
        <ListItem
          disablePadding>
            <Link to="about-us" smooth={true} duration={750} onClick={toggleDrawer(false)}>
          <ListItemButton
            sx={{ pr: 10, pl: 3 }}>
              <ListItemText>
                <Typography variant="h5" >O nás</Typography>
              </ListItemText>
          </ListItemButton>
          </Link>
        </ListItem>
      </List>
    );

  }
  return (
    <AppBar
      position='sticky'
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main,
      }}
    >
      <Drawer anchor='right'
        sx={{ opacity: 1 }}
        open={open} onClose={toggleDrawer(false)}>
        <DrawerItems />
      </Drawer>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ p:{xs:0, md:5},  display: 'flex', alignItems: 'center', height: { xs: 40, md: 50 } }}>
            <img src={process.env.PUBLIC_URL + "/pictures/thumbnail_logotyp-2-gy.png"
            } alt="Logo" style={{ height: 'inherit' }} />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none', md: 'flex' }, justifyContent: 'left' }}>
            <Button
              onClick={() => { navigate(baseurl + "/"); }}
              sx={{ my: 2, color: 'white', display: 'block', }}>
              Servis okien
            </Button>
            <Button
              onClick={() => { navigate(baseurl + "/tieniaca-technika"); }}
              sx={{ my: 2, color: 'white', display: 'block', }}>
              Tieniaca technika
            </Button>
            <Button
              onClick={() => { navigate(baseurl + "/siete-proti-hmyzu"); }}
              sx={{ my: 2, color: 'white', display: 'block', }}>
              Siete proti hmyzu
            </Button>
            <Button
              onClick={() => { navigate(baseurl + "/plastove-okna"); }}
              sx={{ my: 2, color: 'white', display: 'block', }}>
              Plastové okná
            </Button>
            <Link to="qna" smooth={true} duration={750}>
            <Button
              sx={{ my: 2, color: 'white', display: 'block', }}>
                Otázky a odpovede
            </Button>
            </Link>
            <Link to="about-us" smooth={true} duration={750}>
            <Button
              sx={{ my: 2, color: 'white', display: 'block', }}>
                O nás
            </Button>
            </Link>
          </Box>


          <Box sx={{
            ml: 0,
            flexGrow: {xs:5, sm:10},
            display: { xs: 'flex', sm: 'flex', md: 'flex' },
            justifyContent: 'right'
          }}>
            <IconButton size='small'
              href="tel:+421911671234"
              onClick={scrollToComponent('contact-form')}
              sx={{
                ml: 0,
                pt: 1,
                color: 'white',
                fontSize: 28
              }}>
              <CallIcon fontSize='inherit' />
            </IconButton>
          </Box>
          <Box sx={{
            ml: -3,
            flexGrow: 1,
            display: { xs: 'flex', sm: 'flex', md: 'none' },
            justifyContent: 'right'
          }}>
            <IconButton
              size="large"
              color="inherit"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton >
          </Box>
          <Typography variant="h6" sx={{ display: { xs: 'none', xl: 'flex' }, ml: 1 }}>{contactFormText.phone}</Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default NavBar;
