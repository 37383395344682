// Router.tsx
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { BlindsMoreSpecs } from './components/BlindsMore';
import blindsPage from './components/blindsPage';
import ScrollTop from './components/main/scrollNav';
import { BlindsDetails, EmergencyDetails, ForgingCmp, MoreServicesDetails, RegularService, Sealing, StuckWindows, WindowsDetails, WindowsillDetails, Winter_Summer } from './components/main/serviceDetails';
import mainPage from './components/mainPage';
import { DoorNets } from './components/nets/DoorNets';
import { WindowNets } from './components/nets/WindowNets';
import netsPage from './components/netsPage';
import { WindowDetailsSpecs } from './components/windows/windowDetail';
import windowsPage from './components/windowsPage';
interface RouteConfig {
  path: string;
  component: React.ComponentType<any>;
}3




export const baseurl = ''; 


const routes: RouteConfig[] = [
  {
    path: baseurl + '/',
    component: mainPage,
  },

  {
    path: baseurl + '/tieniaca-technika',
    component: blindsPage,
  },
  {
    path: baseurl +'/plastove-okna',
    component: windowsPage,
  },


  {
    path: baseurl +'/oprava-vymena-kovania',
    component: ForgingCmp,
  },
  {
    path: baseurl +'/zaseknute-okna-dvere',
    component: StuckWindows,
  },
  {
    path: baseurl +'/zimny-letny-rezim',
    component: Winter_Summer,
  },
  {
    path: baseurl +'/pravidelna-udrzba',
    component: RegularService,
  },
  {
    path: baseurl +'/vymena-tesneni',
    component: Sealing,
  },

  {
    path: baseurl +'/havarijny-servis',
    component: EmergencyDetails,
  },
  {
    path: baseurl +'/siete-proti-hmyzu',
    component: netsPage,
  },

  {
      path: baseurl + "/plastove-okna/:productId",
      component: WindowDetailsSpecs,
  },
  {
      path: baseurl + "/tieniaca-technika/:productId",
      component: BlindsMoreSpecs,
  },

  {
    path: baseurl + "/siete-proti-hmyzu/okenne-siete",
    component: WindowNets
  },
  {
    path: baseurl + "/siete-proti-hmyzu/dverove-siete",
    component: DoorNets
  },
  

    { 
        path: '*',
        component: mainPage,
    }
];

const AppRouter = () => {


  return (
    <BrowserRouter>
    <ScrollTop />
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={<route.component />} />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

3

export default AppRouter;
